import { useEffect, useState } from 'react';
import styled from 'styled-components'

import iconPlay from '../../assets/imgs/icon-play.svg'
import iconPause from '../../assets/imgs/icon-pause.svg'

const Wrapper = styled.div`
    cursor: pointer;

    img {
        width: 60px;
    }
`

export default function MusicPlayer({
    isPlay,
    togglePlay
}: {
    isPlay: boolean,
    togglePlay: () => void
}) {
    const [audio] = useState(new Audio('assets/music.mp3'));

    useEffect(() => {
        isPlay ? audio.play() : audio.pause();
    }, [
        isPlay
    ])

    return (
        <div>

            <Wrapper onClick={() => togglePlay()}>
                {
                    !isPlay ? <img src={iconPlay} alt="Play" />
                        : <img src={iconPause} alt="Pause" />
                }
            </Wrapper>

            <audio src='/assets/music.mp3' />

        </div>
    );
}