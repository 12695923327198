import { useState } from 'react';
import { Close, Menu as MenuIcon } from '@mui/icons-material';
import styled from 'styled-components'

import MusicPlayer from '../../components/MusicPlayer';
import Splash from '../../components/Splash';

import logo from '../../assets/imgs/logo.svg'
import backImg from '../../assets/imgs/background.png'
import backMobileImg from '../../assets/imgs/background_mobile.png'
import homeLogo from '../../assets/imgs/home-logo.png'
import bottomImg from '../../assets/imgs/bottom.svg'
import iconTwitter from '../../assets/imgs/icon-twitter.svg'
import iconDiscord from '../../assets/imgs/icon-discord.svg'
import iconBuyBones from '../../assets/imgs/buy-bones.png'

import './style.css';


const Wrapper = styled.div`
`

const HeaderLink = styled.a`
    color: #FFEECB;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;
    text-decoration: none;

    &:hover, &:focus {
        border-bottom: 1px solid #FFEECB;
    }
`

const BuyLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    height: 42px;

    img {
        height: 100%;
    }
        
    &:hover, &:focus {
        opacity: 0.8;
    }
`

const SocialLink = styled.a`
    text-decoration: none;
    cursor: pointer;

    &.discord, &.twitter {
        width: 42px;
        height: 42px;
        
        &:hover, &:focus {
            opacity: 0.8;
        }
    }

    &.shop {
        height: 42px;
        line-height: 40px;
        border-radius: 8px;
        color: #E5E5E5;
        background: #3172FF;
        border: 1px solid #3172FF;
        font-family: ObviouslyBold;
        padding: 0px 12px;

        &:hover, &:focus {
            background: #1760ff;
        }
    }
`

const HomeSection = styled.div`
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-image: url(${backImg});
    background-size: cover;
    background-position: center;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        background-image: url(${backMobileImg});
    `};

`

const ExploreLink = styled.a`
    width: fit-content;
    text-decoration: none;
    background: #F98147;
    border: 1px solid #F98147;
    border-radius: 6px;
    color: #FFEECB;
    font-family: ObviouslyBold;
    font-size: 18px;
    padding: 12px;

    &:hover, &:focus {
        background: #f9915e;
    }
`

const MobileWrapper = styled.div`

    display: none;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        gap: 12px;
        align-items: center;
    `};
`

const MenuToggle = styled(MenuIcon)`
    background: white;
    color: #3172FF;
    padding: 8px;
    border: 1px solid #3172FF;
    border-radius: 4px;
`

const MobileMenu = styled.div`
    background: #3172FF;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;    
    padding: 24px;
`

const MenuClose = styled(Close)`
    background: white;
    color: #3172FF;
    padding: 8px;
    border: 1px solid #3172FF;
    border-radius: 4px;
`

const MobileLink = styled.a`
    color: #FFEECB;
    text-decoration: none;
    font-family: ObviouslyWide;
    font-size: 42px;
    
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 32px;
    `};
`

export default function Home() {

    const [loading, setLoading] = useState(true);
    const [isPlay, setPlay] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const togglePlay = () => {
        setPlay(!isPlay);
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <>

            {
                loading ?
                    <Splash onLoaded={() => setLoading(false)} /> :
                    <Wrapper>

                        <div className='header'>

                            <div className='header-container'>

                                <a href='https://www.souldogs.city/' target='_blank'>
                                    <img src={logo} alt="Logo" width={64} />
                                </a>

                                <div className='header-links'>

                                    <HeaderLink target='_blank' href='https://docs.souldogs.city/'>About</HeaderLink>
                                    <HeaderLink target='_blank' href='https://rarity.souldogs.city/'>Rarity</HeaderLink>
                                    <HeaderLink target='_blank' href='https://docs.souldogs.city/soul-dogs-city-metaverse/roadmap'>Roadmap</HeaderLink>
                                    <HeaderLink target='_blank' href='https://docs.souldogs.city/for-sale-dog-houses/a-new-world'>Houses</HeaderLink>
                                    {/* <HeaderLink target='_blank' href='https://lotto.souldogs.city/'>Lucky Dog</HeaderLink> */}
                                    <HeaderLink target='_blank' href='https://roll.souldogs.city/'>Dice</HeaderLink>
                                    <HeaderLink target='_blank' href='https://open.spotify.com/playlist/7ypAdnWgSbQqsYg2zsSpVq?si=WEZNT3BhROW0dfaA-0BisQ'>Vibes</HeaderLink>

                                </div>

                                <div className='social-links'>

                                    <BuyLink className='buy' target='_blank' href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE&inputAmount=20&outputAmount=301236.95&fixed=in'>
                                        <img src={iconBuyBones} alt="Buy Bones" />
                                    </BuyLink>
                                    <SocialLink className='twitter' target='_blank' href='https://twitter.com/SoulDogsCity'>
                                        <img src={iconTwitter} alt="Twitter" />
                                    </SocialLink>
                                    <SocialLink className='discord' target='_blank' href='https://discord.gg/souldogscity'>
                                        <img src={iconDiscord} alt="Discord" />
                                    </SocialLink>
                                    <SocialLink className='shop' target='_blank' href='https://www.magiceden.io/creators/soul_dogs'>
                                        SHOP
                                    </SocialLink>

                                </div>

                                <MobileWrapper>
                                    <SocialLink className='shop' target='_blank' href='https://www.magiceden.io/creators/soul_dogs'>
                                        SHOP
                                    </SocialLink>
                                    <MenuToggle onClick={() => toggleMenu()} />
                                </MobileWrapper>

                            </div>

                        </div>

                        <div className='body'>

                            <HomeSection>

                                <div className='top-wrapper'>
                                    <h1>WELCOME TO</h1>
                                    <img src={homeLogo} alt="Logo" />
                                    <h4>A retro inspired metaverse for rebels, misfits and dreamers</h4>
                                    <ExploreLink target='_blank' href='https://explore.souldogs.city'>EXPLORE</ExploreLink>
                                </div>

                                <div className='bottom-wrapper'>
                                    <MusicPlayer isPlay={isPlay} togglePlay={() => togglePlay()} />
                                    <img className='feel' src={bottomImg} alt="Bottom" />
                                </div>


                            </HomeSection>

                        </div>

                    </Wrapper>
            }

            {
                menuOpen ?
                    <MobileMenu>

                        <div className='menu-close'>
                            <MenuClose onClick={() => toggleMenu()} />
                        </div>

                        <div className='mobile-links'>
                            <MobileLink target='_blank' href='https://docs.souldogs.city/'>About</MobileLink>
                            <MobileLink target='_blank' href='https://rarity.souldogs.city/'>Rarity</MobileLink>
                            <MobileLink target='_blank' href='https://docs.souldogs.city/soul-dogs-city-metaverse/roadmap'>Roadmap</MobileLink>
                            <MobileLink target='_blank' href='https://docs.souldogs.city/for-sale-dog-houses/a-new-world'>Houses</MobileLink>
                            {/* <MobileLink target='_blank' href='https://lotto.souldogs.city/'>Lucky Dog</MobileLink> */}
                            <MobileLink target='_blank' href='https://roll.souldogs.city/'>Dice</MobileLink>
                            <MobileLink target='_blank' href='https://open.spotify.com/playlist/7ypAdnWgSbQqsYg2zsSpVq?si=WEZNT3BhROW0dfaA-0BisQ'>Vibes</MobileLink>
                        </div>

                        <div className='divider' />

                        <div className='mobile-socials'>
                            <SocialLink className='twitter' target='_blank' href='https://twitter.com/SoulDogsCity'>
                                <img src={iconTwitter} alt="Twitter" />
                            </SocialLink>
                            <SocialLink className='discord' target='_blank' href='https://discord.gg/souldogscity'>
                                <img src={iconDiscord} alt="Discord" />
                            </SocialLink>
                            <BuyLink className='buy' target='_blank' href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE&inputAmount=20&outputAmount=301236.95&fixed=in'>
                                <img src={iconBuyBones} alt="Buy Bones" />
                            </BuyLink>
                        </div>

                    </MobileMenu>
                    : null
            }

        </ >
    )
}
