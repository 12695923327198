import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom';

import './index.css';
import App from './pages/App';
import ThemeProvider from './theme';

ReactDOM.render(
  <StrictMode>
    <HashRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </HashRouter>
  </StrictMode>,
  document.getElementById('root')
);
