import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Home from './Home';

export default function App() {

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/" component={Home} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </Suspense>
  )
}
