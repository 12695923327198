import { useEffect, useState } from 'react';
import styled from 'styled-components'

import loadingImg from '../../assets/imgs/loading.gif'
import backImg from '../../assets/imgs/background.png'

import './style.css';

const LoadingWrapper = styled.div`
    background: #FC363C;
    height: 100vh;
`

const BgWrapper = styled.div`
    top: 0;
    right: 100vw;
    height: 100vh;
    width: 100vw;
    position: fixed;
    opacity: 0;
    transition: all 2s ease-out;

    &.active {
        right: 0;
        opacity: 1;
    }

    img {
        width: 100%;
        min-height: 100vh;
        object-fit: cover;
    }
`

const LoadingGif = styled.img`
    width: 420px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 320px;
    `};
`

export default function Splash({
    onLoaded
}: {
    onLoaded: () => void
}) {

    const showTime = 3000;
    const loadingTime = 4000;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            onLoaded();
        }, loadingTime)
    }, [])

    /*
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, loadingTime)
    }, [])

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                onLoaded();
            }, showTime)
        }
    }, [
        loading,
        onLoaded
    ])
    */

    return (
        <div className='wrapper'>

            <LoadingWrapper className={loading ? 'active' : ''}>

                {
                    loading ?
                        <div className='loading-wrapper'>
                            <div className='logo-wrapper'>
                                <LoadingGif src={loadingImg} alt="Loading" />
                            </div>
                            <div className='progress'>
                                <div className='bar'></div>
                            </div>
                        </div>
                        : null
                }

            </LoadingWrapper>

            {
                /*
                        <BgWrapper className={loading ? '' : 'active'}
                            style={{ backgroundImage: `url(${backImg})`, backgroundSize: `cover`, backgroundPosition: `center` }}>
                        </BgWrapper>
                */
            }

        </div >
    )
}
